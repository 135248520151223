<template>
  <div>
    <div>
      <div class="operationControl personalSpecialOrder">
        <div class="searchbox">
          <el-popover
            placement="bottom"
            width="100">
              <div class="flexac">
                <el-button type="text" @click="downloadList(1)" :disabled="!projectId">xls</el-button>
                <el-button type="text" @click="downloadList(2)" :disabled="!allowPrint || !projectId">pdf</el-button>
              </div>
            <el-button class="bgc-bv" round slot="reference">下载答疑记录</el-button>
          </el-popover>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
            style="width: 100%" stripe :header-cell-style="tableHeader">
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
            <el-table-column label="培训项目名称" align="left" show-overflow-tooltip prop="projectName" min-width="150" />
            <el-table-column label="发起人" align="left" show-overflow-tooltip prop="userName" width="100px" />
            <el-table-column label="发起人身份证号" align="left" show-overflow-tooltip prop="idcard" width="150px" />
            <el-table-column label="发起内容" align="left" show-overflow-tooltip prop="content" min-width="150" />
            <el-table-column label="发起时间" align="left" show-overflow-tooltip prop="createTime" width="150">
              <template slot-scope="scope">
                {{ scope.row.createTime | moment }}
              </template>
            </el-table-column>
            <el-table-column label="回复人" align="left" show-overflow-tooltip prop="replyName" width="100" />
            <el-table-column label="回复人身份" align="left" show-overflow-tooltip prop="replyIdentity" width="100" />
            <el-table-column label="回复内容" align="left" show-overflow-tooltip prop="replyContent" min-width="150" />
            <el-table-column label="回复时间" align="left" show-overflow-tooltip prop="replyTime" width="150">
              <template slot-scope="scope">
                {{ scope.row.replyTime | moment }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "ciq_type_1",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 班级id
    projectId: {
      type: String,
      default: "",
    },
    // 学员id
    userId: {
      type: String,
      default: "",
    },
    // 是否结业
    graduationState: {
      type: String,
      default: "",
    },
    // tap选项卡
    tabs: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 是否可以下载
      allowPrint: false,
    };
  },
  watch: {
    // 监听 - tap选项卡
    tabs: {
      handler: function (val) {
        if (val == "type_3" && this.projectId) {
          this.getData(-1);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() { },
  created() { 
  },
  methods: {
    // 查询 - 列表  
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        userId: this.userId,
        graduationState: this.graduationState
      };
      this.doFetch(
        {
          url: "/sh/project/archives/answeringQuestionList",
          params,
          pageNum,
        },
      );
      this.get_allow_print();
    },
    // 获取 - 是否可以下载
    get_allow_print() {
      this.$post("/biz/project/getProjectInfo", { projectId: this.projectId }).then((res) => {
        this.allowPrint = res.data.allowPrint;
      });
    },
    // 导出 - 列表数据
    downloadList(type) {
      let cData = {
        projectId: this.projectId,
        graduationState: this.graduationState
      }
      // 导出 - 系在答疑记录(xls)
      if (type == 1) {
        cData.exportType = '10';
      }
      // 导出 - 系在答疑记录(pdf)
      if (type == 2) {
        cData.exportType = '20';
      }
      this.$post("/sh/project/archives/answeringQuestionExport", cData).then((res) => {
        if (res.status == '0') {
          for (let item of res.data) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
